import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"

const ImgContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;

  padding: 24px;
  cursor: pointer;
`

const ImgLayout = styled.div`
  grid-auto-columns: 1fr;
  max-width: 560px;
  margin: auto;

  @media (min-width: 1020px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    max-width: 100%;
  }
`

const ImageWrapper = styled.div`
  padding: 20px;
`

const Sketchbook = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sketchbook Drawings | Art by Izabelle Wilding</title>
        <meta name="ketchbook Drawings Art by Izabelle Wilding"  />
        <link rel="canonical" href="http://izabelleart.com/sketchbook" />
      </Helmet>
      <Layout>
        <ImgLayout>
          <ImageWrapper>
            <StaticImage
              src="../images/sketchbook/blue_tit.jpg"
              alt="Blue Tit - Sketch by Izabelle Wilding"
              layout="fullWidth"
              loading="lazy"
              height="300"
            />
          </ImageWrapper>
          <ImageWrapper>
            <StaticImage
              src="../images/sketchbook/amo-te.jpg"
              alt="Amote - Sketch by Izabelle Wilding"
              layout="fullWidth"
              loading="lazy"
              height="300"
            />
          </ImageWrapper>
          <ImageWrapper>
            <StaticImage
              src="../images/sketchbook/echinacea_montage.jpg"
              alt="Echinacea Montage - Sketch by Izabelle Wilding"
              layout="fullWidth"
              loading="lazy"
              height="300"
            />
          </ImageWrapper>
          <ImageWrapper>
            <StaticImage
              src="../images/sketchbook/eeple_queen.jpg"
              alt="Eeple Queen - Sketch by Izabelle Wilding"
              layout="fullWidth"
              loading="lazy"
              height="300"
            />
          </ImageWrapper>
        </ImgLayout>
      </Layout>
    </>
  )
}

export default Sketchbook
